module.exports = {
    LOCALES: ["en", "fr", "nl", "pt", "de", "es", "tr", "ar", "it", "ru", "ko", "zh"],
    DEFAULT_LOCALE: "en",
    NAMESPACES: [
        "common",
        "indices",
        "signin",
        "navbar",
        "footer",
        "tradingBot",
        "rating",
        "tokenDetails",
        "setting",
        "market",
        "tmai",
        "categories",
        "page_titles",
        "tokenlists",
    ],
};
