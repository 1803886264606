'use client';

import error_image from '@/public/images/error/500.svg';
import * as Sentry from '@sentry/nextjs';
import Image from 'next/image';
import { useEffect } from 'react';

// importing multilanguage
import { useTranslation } from '@/components/translation/TranslationsProvider';
import Link from 'next/link';
import { Button } from '../ui/button';

type Props = Readonly<{
  error: Error & { digest?: string };
}>;

const ErrorComp: React.FC<Props> = ({ error }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (error) {
      Sentry.captureException(error);
    }
  }, [error]);

  return (
    <div className="flex h-screen w-full flex-col items-center gap-4 text-center">
      <div
        className="flex h-[172px] w-full flex-col justify-center gap-4 bg-[#F2F4FA] text-center dark:bg-[#111116]"
        style={{ padding: '40px 0' }}
      >
        <h2 className="text-2xl font-bold">{t('common:somethingWentWrong')}</h2>
        <p
          className="text-base text-[#71717A] dark:text-[#A1A1AA]"
          data-testid="500-sub-heading"
        >
          {t('common:weEncounteredAnError')}
        </p>

        <div>
          <Link href="/" replace>
            <Button className="h-10 w-auto bg-primary-yellow text-sm text-[#09090B] hover:bg-primary-graph-yellow lg:min-w-[100px]">
              {t('common:goBackHome')}
            </Button>
          </Link>
        </div>
      </div>
      <Image
        src={error_image}
        alt="Something went wrong"
        title="Something went wrong"
      />
    </div>
  );
};

export default ErrorComp;
