import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function decimal(number: any, fractionDigits: number = 2) {
  if (number === null || number === undefined || isNaN(number)) {
    return number;
  }
  return parseFloat(number.toFixed(fractionDigits));
}

export function convertToShortNum(value: any) {
  // prettier-ignore
  // @ts-ignore
  return new Intl.NumberFormat("en", { notation: "compact", compactDisplay: "short" }).format(value);
}

export const filterDataByDateAndROI = (
  data: any,
  currentData: Date,
  roiFilter: any,
) => {
  return data.filter(({ END_PERIOD }: any) => {
    const targetDate = new Date(END_PERIOD).getTime();
    const YTD = new Date(`${currentData.getFullYear()}-01-01`).getTime();

    if (roiFilter.filterValue === 0) {
      return true;
    } else if (roiFilter.filterValue < 0) {
      return YTD <= targetDate;
    } else {
      const startDate =
        currentData.getTime() - roiFilter.filterValue * 24 * 3600 * 1000;
      return currentData.getTime() >= targetDate && targetDate >= startDate;
    }
  });
};

export const filterChartDataByTime = <TData extends { DATE: string }>(
  data: TData[],
  filterValue: number,
) => {
  const fullData = filterValue === 0 ? data : data.slice();
  const today = new Date();
  if (filterValue === -1) {
    // YTD
    const ytd = new Date(new Date().getFullYear(), 0, 1);
    const filteredData = fullData.filter((item) => new Date(item.DATE) >= ytd);
    return filteredData;
  } else if (filterValue === 0) {
    // MAX
    return fullData;
  } else {
    today.setDate(today.getDate() - filterValue - 1);
    const filteredData = fullData.filter(
      (item) => new Date(item.DATE) >= today,
    );
    return filteredData;
  }
};

export const isNullish = (value: unknown) => {
  return value === null || value === undefined;
};
