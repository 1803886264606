"use client";

import { NEXT_LOCALE_COOKIE_KEY } from "@/constants/cookie";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import cookies from "js-cookie";

const { LOCALES, NAMESPACES } = require("../../../i18n.config");

type TranslationsProviderValue = {
    t: (key: string, params?: Record<string, string | number>) => string;
    locale: string;
    changeLocale: (newLocale: string) => void;
};

const TranslationsContext = createContext<TranslationsProviderValue>(null as any);

export type TranslationsProviderProps = React.PropsWithChildren & {
    locale: string;
    translations: Record<string, Record<string, string>>;
};

const fetchAllTranslations = () =>
    Promise.all(
        LOCALES.map(async (locale: string) =>
            (await Promise.all(NAMESPACES.map((namespace: string) => import(`../../../public/locales/${locale}/${namespace}.json`)))).reduce(
                (acc, cur, index) => ({ ...acc, [NAMESPACES[index]]: cur }),
                {},
            ),
        ),
    ).then((allTranslationSets) => allTranslationSets.reduce((acc, cur, index) => ({ ...acc, [LOCALES[index]]: cur }), {}));

export const TranslationsProvider = ({ children, locale: localeProp, translations }: TranslationsProviderProps) => {
    const [locale, setLocale] = useState(localeProp);
    const [allTranslationSets, setAllTranslationSets] = useState<Record<string, any>>({});

    const changeLocale = useCallback((newLocale: string) => {
        setLocale(newLocale);
        cookies.set(NEXT_LOCALE_COOKIE_KEY, newLocale);

        const currentSearch = window.location.search;
        const currentPathname = window.location.pathname.split("/").slice(2).join("/");

        const newUrl = new URL(window.location.href);

        newUrl.pathname = `/${newLocale}/` + currentPathname;
        if (currentSearch) {
            newUrl.search = currentSearch;
        }

        window.history.pushState(null, "", newUrl.toString());
    }, []);

    useEffect(() => {
        fetchAllTranslations().then(setAllTranslationSets);
    }, []);

    const activeTranslations = (locale === localeProp ? translations : allTranslationSets[locale]) ?? translations;

    const mergedTranslations = useMemo(() => {
        return Object.keys(activeTranslations).reduce(
            (acc, namespace) => ({
                ...acc,
                ...activeTranslations[namespace],
            }),
            {} as Record<string, string>,
        );
    }, [activeTranslations]);

    const t = useCallback(
        (key: string, params?: Record<string, string | number>) => {
            const namespace = key.includes(":") ? key.split(":")[0] : undefined;
            const translationKey = key.includes(":") ? key.split(":")[1] : key;

            let translation: any;
            if (namespace) {
                const namespaceTranslations = activeTranslations[namespace];
                translation = namespaceTranslations ? namespaceTranslations[translationKey] : undefined;
            } else {
                translation = mergedTranslations[translationKey];
            }

            if (!translation) return key;

            if (params) {
                Object.keys(params).forEach((paramKey) => {
                    translation = translation.replace(`{{${paramKey}}}`, params[paramKey] as string);
                });
            }

            return translation;
        },
        [mergedTranslations, activeTranslations],
    );

    const providerValue: TranslationsProviderValue = useMemo(() => ({ t, locale, changeLocale }), [changeLocale, locale, t]);

    return <TranslationsContext.Provider value={providerValue}>{children}</TranslationsContext.Provider>;
};

export default TranslationsProvider;

export const useTranslation = () => useContext(TranslationsContext);
