"use client";

import Layout from "@/components/layout/layout";
import ErrorComp from "@/components/ErrorPage/ErrorPage";

export default function GlobalError({
  error,
}: {
  error: Error & { digest?: string };
}) {
  return (
    <Layout>
      <ErrorComp error={error} />
    </Layout>
  );
}
